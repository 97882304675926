import * as React from "react"
import Layout from '../components/layout'
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import styled from 'styled-components'

const Description = styled.p`
  font-size: 16px;
  line-height: 150%;
  max-width: 490px;
`

const Title = styled.h3`
  font-size: 24px;
  font-weight: bold;

  & span{
    font-size: 16px;
    font-weight: normal;
  }
`

const Wrap = styled.div`
  width: 950px;
  height: 720px;
  margin: 64px;

  @media screen and (max-width: 1200px) {
    width: 800px;
    height: 635px;
  }

  @media screen and (max-width: 1000px) {
    width: calc(100% - 32px);
    height: auto;
    margin: 16px;
  }
`

// markup
const Media = () => {
  return (
    <Layout flipped={true}>
       <Wrap>
        <LiteYouTubeEmbed 
          id="D6cs4wXK4_Y"
          title="GOSH! - Sleight of Hand by Jack Paton and Samuel Pratt - HEATH 2023"
          poster="maxresdefault"
        />
        <Title>GOSH! <span>August 2023</span></Title>
        <Description>Sleight of Hand by Jack Paton and Samuel Pratt. Filmed in Gothenburg, premiered at Cardistry Con 2023.</Description>
      </Wrap>
      <Wrap>
        <LiteYouTubeEmbed 
          id="g675Tf3Q318"
          title="green by noel and aviv"
          poster="maxresdefault"
        />
        <Title>green by noel and aviv <span>December 2022</span></Title>
        <Description>Cardistry and sleight of hand by Noel Heath and Aviv Moraly. Filmed in Gothenburg and Copenhagen.</Description>
      </Wrap>
      <Wrap>
        <LiteYouTubeEmbed 
          id="HTEzwmYpEhM"
          title="Papier Mâché - Sleight of Hand by Ethan Newnham - HEATH 2022"
          poster="maxresdefault"
        />
        <Title>Papier Mâché <span>September 2022</span></Title>
        <Description>Sleight of hand created and performed by Ethan Newnham, filmed in Melbourne by Kelly Wang.</Description>
      </Wrap>
      <Wrap>
        <LiteYouTubeEmbed 
          id="zGgo3OmcvSA"
          title="Full Tilt - Cardistry By Birger Karlsson - HEATH 2022"
          poster="maxresdefault"
        />
        <Title>Full Tilt <span>August 2022</span></Title>
        <Description>Cardistry created and performed by Birger Karlsson, filmed in Stockholm. Premiered at Card Club 2022.</Description>
      </Wrap>
      <Wrap>
        <LiteYouTubeEmbed 
          id="fyRn4KbVIHk"
          title="SNAP! - Sleight of Hand by Jack Paton - HEATH 2022"
          poster="maxresdefault"
        />
        <Title>SNAP! <span>June 2022</span></Title>
        <Description>Sleight of hand created and performed by Jack Paton, filmed in Glasgow and Edinburgh. Premiered at Cardistry Con 2022.</Description>
      </Wrap>
    </Layout>
  )
}

export default Media
